@font-face {
  font-family: "Dihi";
  src: url("./dihjauti.otf") format("truetype");
}
@font-face {
  font-family: "Dihi-bold-italic";
  src: url("./dihjauti.bold-italic.otf") format("truetype");
}
@font-face {
  font-family: "Dihi-bold";
  src: url("./dihjauti.s-bold.otf") format("truetype");
}
@font-face {
  font-family: "Dihi-italic";
  src: url("./dihjauti.s-italic.otf") format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
html {
  height: 100%;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
