:root {
  --primary-color: "rgb(254, 152, 1)";
  --seconadry-color: "rgb(244, 238, 199)v";
  --accent-color1: "rgb(204, 218, 70)";
  --accent-color2: "rgb(105, 124, 55)";
}

.App {
  text-align: center;
  height: inherit;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.hidden {
  transform: translateX(-100%);
  width: 0;
  opacity: 0;
  z-index: -1;
}
.transition {
  transition: all 0.25s;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.add-groceries-icon:hover {
  text-decoration: none;
}
.on-focus {
  transition: all 0.2s;
}

.on-focus:hover {
  transform: scale(1.02);
}

.add-cursor {
  cursor: pointer;
}

.wrap-text {
  text-wrap: balance !important;
}
